var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-base"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"お名前","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":30,"error-messages":errors,"label":"Name","required":"","type":"text","name":"user_name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":"","type":"email","name":"user_email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"お問い合わせ内容","rules":"required|max:2000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb10",attrs:{"name":"message","label":"Message","counter":2000,"error-messages":errors,"required":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" 送信 ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" クリア ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }