<template>
  <section class="page-base overflow">
  <v-gallery :images="list" class="image-box">
    
      <a href="javascript:void(0);"
          :data-image="img.url"
          v-for="(img, index) in list"
          :key="index"
          >
        <div class="bgbox">
          <img :src="img.thumnail">
        </div>
        <div class="img-title"></div>
      </a>
  </v-gallery>

  </section>
</template>
<script>

export default {
  data () {
    return {
      list: [
        {
          //kabekoji
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510790/web-img/%E5%A3%81%E3%81%93%E3%81%97%E3%82%99__B2%E3%83%9B%E3%82%9A%E3%82%B9%E3%82%BF%E3%83%BC_B_0823a_ujthm8.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510790/web-img/%E5%A3%81%E3%81%93%E3%81%97%E3%82%99__B2%E3%83%9B%E3%82%9A%E3%82%B9%E3%82%BF%E3%83%BC_B_0823a_ujthm8.jpg'
        },
        {
          //kabekoji
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510790/web-img/%E5%A3%81%E3%81%93%E3%81%97%E3%82%99__B2%E3%83%9B%E3%82%9A%E3%82%B9%E3%82%BF%E3%83%BC_A_0823a_njtghr.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510790/web-img/%E5%A3%81%E3%81%93%E3%81%97%E3%82%99__B2%E3%83%9B%E3%82%9A%E3%82%B9%E3%82%BF%E3%83%BC_A_0823a_njtghr.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510784/web-img/2302044027_yflble.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510784/web-img/2302044027_yflble.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510782/web-img/2302044246_rbfusp.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510782/web-img/2302044246_rbfusp.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510782/web-img/2302044081_hrogu5.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510782/web-img/2302044081_hrogu5.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510782/web-img/2302043672_wrubta.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677510782/web-img/2302043672_wrubta.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510776/web-img/0003a_ll2xcw.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510776/web-img/0003a_ll2xcw.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510775/web-img/0009_xfvaov.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510775/web-img/0009_xfvaov.jpg'
        },
        {
          //katori
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510717/web-img/_87A9227_zq2pnu.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510717/web-img/_87A9227_zq2pnu.jpg'
        },
        {
          //katori
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510713/web-img/_87A9179_vw9nyq.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510713/web-img/_87A9179_vw9nyq.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510709/web-img/_87A9256_oyqq28.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510709/web-img/_87A9256_oyqq28.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510239/web-img/_87A3619_anmsrr.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510239/web-img/_87A3619_anmsrr.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510243/web-img/_87A3515_hqt4sz.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510243/web-img/_87A3515_hqt4sz.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510447/web-img/_87A1697s_1_zmvgcc.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510447/web-img/_87A1697s_1_zmvgcc.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510448/web-img/_87A1552_1_lmt3bq.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510448/web-img/_87A1552_1_lmt3bq.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510448/web-img/_87A2082s_1_apdod0.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510448/web-img/_87A2082s_1_apdod0.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510448/web-img/_87A1753s_1_pnfivq.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510448/web-img/_87A1753s_1_pnfivq.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510448/web-img/_87A1713s_1_qnsff3.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510448/web-img/_87A1713s_1_qnsff3.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510448/web-img/_87A1770s_1_silxqy.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510448/web-img/_87A1770s_1_silxqy.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509265/web-img/_87A0490r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_dtx3t6.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509265/web-img/_87A0490r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_dtx3t6.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509264/web-img/_87A0077r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_bss5ml.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509264/web-img/_87A0077r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_bss5ml.jpg'
        },
        {
          //taobi
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509263/web-img/_87A0469r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_bfzoyz.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509263/web-img/_87A0469r_%E3%81%AE%E3%82%B3%E3%83%92%E3%82%9A%E3%83%BC_bfzoyz.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509254/web-img/DSC02869_wittse.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509254/web-img/DSC02869_wittse.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509253/web-img/DSC02935_hswgmh.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509253/web-img/DSC02935_hswgmh.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509252/web-img/DSC03055_oexyit.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509252/web-img/DSC03055_oexyit.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509252/web-img/DSC02906_fqcppn.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509252/web-img/DSC02906_fqcppn.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509251/web-img/DSC02987_iqpa4g.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509251/web-img/DSC02987_iqpa4g.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509084/web-img/_87A3710_bcyaie.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509084/web-img/_87A3710_bcyaie.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509091/web-img/0032_02_hbqadh.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677509091/web-img/0032_02_hbqadh.jpg'
        },
        {
          //ハンカチ
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509096/web-img/_87A3748_qwzuxs.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509096/web-img/_87A3748_qwzuxs.jpg'
        },
        {
          //bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510568/web-img/_87A0804_yfm89w.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510568/web-img/_87A0804_yfm89w.jpg'
        },
        {
          //bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510567/web-img/_87A0347_dzhqlp.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510567/web-img/_87A0347_dzhqlp.jpg'
        },
        {
          //bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510566/web-img/_87A9838_gyg1em.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510566/web-img/_87A9838_gyg1em.jpg'
        },
        {
          //bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510566/web-img/_87A0025_te4tbv.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510566/web-img/_87A0025_te4tbv.jpg'
        },
        {
          //bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510566/web-img/_87A0839-2_p7u8hk.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510566/web-img/_87A0839-2_p7u8hk.jpg'
        },
        {
          //gotisou
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510558/web-img/0034_02_wjy1vb.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510558/web-img/0034_02_wjy1vb.jpg'
        },
        {
          //gotisou
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510559/web-img/0034_03_iipruq.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510559/web-img/0034_03_iipruq.jpg'
        },
        {
          // atomos?
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506663/web-img/0007_qgrs1h.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506663/web-img/0007_qgrs1h.jpg'
        },
        {
          // sho
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506661/web-img/0006_hz66xa.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506661/web-img/0006_hz66xa.jpg'
        },
        {
          // eri?
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506662/web-img/0018_qpmocj.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506662/web-img/0018_qpmocj.jpg'
        },
        {
          //erick
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506660/web-img/006b_cqlwrm.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506660/web-img/006b_cqlwrm.jpg'
        },
        {
          //nonchan
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506660/web-img/n_022_rouwcw.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506660/web-img/n_022_rouwcw.jpg'
        },
        {
          //irojikake
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506660/web-img/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88_2023-01-26_0.53.08_b9mihb.png',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506660/web-img/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88_2023-01-26_0.53.08_b9mihb.png'
        },
        {
          //sho
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506658/web-img/0014_zoag5z.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506658/web-img/0014_zoag5z.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506656/web-img/0016_yr0fpg.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506656/web-img/0016_yr0fpg.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506656/web-img/0022_ukklrg.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506656/web-img/0022_ukklrg.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506656/web-img/10_yytnbw.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506656/web-img/10_yytnbw.jpg'
        },
        {
          // eri
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506654/web-img/0005_iohsef.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506654/web-img/0005_iohsef.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506653/web-img/0022_a_h2gtat.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506653/web-img/0022_a_h2gtat.jpg'
        },
        {
          //erick bag
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506651/web-img/_87A0349_sqrrop.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506651/web-img/_87A0349_sqrrop.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506651/web-img/0012a_kzicof.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506651/web-img/0012a_kzicof.jpg'
        },
        {
          //sakamoto
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506650/web-img/0017_vmkr1l.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506650/web-img/0017_vmkr1l.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506647/web-img/02-03_pjfem2.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506647/web-img/02-03_pjfem2.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506647/web-img/0015_wizwb5.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506647/web-img/0015_wizwb5.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506646/web-img/230126_3047_agwau4.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506646/web-img/230126_3047_agwau4.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506645/web-img/0012_n4y5ie.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506645/web-img/0012_n4y5ie.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506644/web-img/08-09_nkbywc.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506644/web-img/08-09_nkbywc.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506644/web-img/0004a_lhqrdl.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506644/web-img/0004a_lhqrdl.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506644/web-img/0010_1_o5b77t.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506644/web-img/0010_1_o5b77t.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/22-23_foobw4.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506643/web-img/22-23_foobw4.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/_87A7795_qiio6b.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/_87A7795_qiio6b.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/230126_2702_vimwau.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506643/web-img/230126_2702_vimwau.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/0034_01_vlir0d.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506643/web-img/0034_01_vlir0d.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506643/web-img/0004_deowug.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506643/web-img/0004_deowug.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506642/web-img/tumblr_no6i7qYC3y1st3mcno1_1280_klzvul.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506642/web-img/tumblr_no6i7qYC3y1st3mcno1_1280_klzvul.jpg'
        },
        {
          //SHO IKKA
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506641/web-img/0021_%EF%BC%91_pqsdt3.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506641/web-img/0021_%EF%BC%91_pqsdt3.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506642/web-img/0011_dsgfr0.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506642/web-img/0011_dsgfr0.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506641/web-img/0008_ijalqe.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506641/web-img/0008_ijalqe.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506641/web-img/_87A5731_d6sfot.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506641/web-img/_87A5731_d6sfot.jpg'
        },
        {
          // work1
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506641/web-img/0031_werqzi.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506641/web-img/0031_werqzi.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677506641/web-img/0013_tbivto.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677506641/web-img/0013_tbivto.jpg'
        },
        {
          // 2018salon
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509104/web-img/%E8%B3%87%E6%96%99_12_vgk9xk.png',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509104/web-img/%E8%B3%87%E6%96%99_12_vgk9xk.png'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509233/web-img/tumblr_o0mqcmdBOQ1st3mcno1_1280_wcwufk.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677509233/web-img/tumblr_o0mqcmdBOQ1st3mcno1_1280_wcwufk.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno2_1280_gyb66m.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno2_1280_gyb66m.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509235/web-img/tumblr_neldeddWIC1st3mcno1_1280_gpxndw.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509235/web-img/tumblr_neldeddWIC1st3mcno1_1280_gpxndw.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509235/web-img/tumblr_neydi4BT111st3mcno1_1280_v2vye7.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509235/web-img/tumblr_neydi4BT111st3mcno1_1280_v2vye7.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno4_1280_ifhbv0.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno4_1280_ifhbv0.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno3_1280_sbbh3k.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509235/web-img/tumblr_o0mqcmdBOQ1st3mcno3_1280_sbbh3k.jpg'
        },
        {
          //tumbler風景
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno1_1280_vq6ohm.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno1_1280_vq6ohm.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno3_1280_el52ow.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno3_1280_el52ow.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno2_1280_wo7zlb.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509241/web-img/tumblr_npz9ulkPoW1st3mcno2_1280_wo7zlb.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510854/web-img/_87A7571_ylab3u.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510854/web-img/_87A7571_ylab3u.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510854/web-img/_87A7540_r1tfqn.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510854/web-img/_87A7540_r1tfqn.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510751/web-img/0023_d8dqgg.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510751/web-img/0023_d8dqgg.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510740/web-img/0023_01_kpf7zh.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510740/web-img/0023_01_kpf7zh.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_nmuc2qjTqJ1st3mcno1_1280_tqmszl.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_nmuc2qjTqJ1st3mcno1_1280_tqmszl.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_nmuc45MXff1st3mcno1_1280_eebthn.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_nmuc45MXff1st3mcno1_1280_eebthn.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_nbz4osb3TD1st3mcno1_1280_ywhh1p.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_nbz4osb3TD1st3mcno1_1280_ywhh1p.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_n1psk1HnN41st3mcno1_1280_cnwozs.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_n1psk1HnN41st3mcno1_1280_cnwozs.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_nb2gq9XOs01st3mcno1_1280_alr5jf.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_nb2gq9XOs01st3mcno1_1280_alr5jf.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_ndqorxUbhH1st3mcno1_1280_rhhoyo.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_ndqorxUbhH1st3mcno1_1280_rhhoyo.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510719/web-img/tumblr_ng9jb3PHRC1st3mcno1_1280_icqcvn.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510719/web-img/tumblr_ng9jb3PHRC1st3mcno1_1280_icqcvn.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510618/web-img/_87A8647_wksoa1.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510618/web-img/_87A8647_wksoa1.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510616/web-img/_87A8832_u70jrp.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510616/web-img/_87A8832_u70jrp.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510615/web-img/_87A8670_dhjizh.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677510615/web-img/_87A8670_dhjizh.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510544/web-img/0019_sqcvyw.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510544/web-img/0019_sqcvyw.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510543/web-img/0020_kpqgp6.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510543/web-img/0020_kpqgp6.jpg'
        },
        {
          //ero
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510515/web-img/0022_c_vsoart.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510515/web-img/0022_c_vsoart.jpg'
        },
        {
          //eri
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510514/web-img/0022_d_gmnk3c.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677510514/web-img/0022_d_gmnk3c.jpg'
        },
        {
          //jinja
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510492/web-img/B3_01_hp2aio.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510492/web-img/B3_01_hp2aio.jpg'
        },
        {
          //sirokuro
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510486/web-img/B3_02_kapmgn.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510486/web-img/B3_02_kapmgn.jpg'
        },
        {
          //sirokuro
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510485/web-img/B3_07_xds1vf.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510485/web-img/B3_07_xds1vf.jpg'
        },
        {
          //sirokuro
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510485/web-img/B3_04_nu7c5m.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/v1677510485/web-img/B3_04_nu7c5m.jpg'
        },
        {
          //zep
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510455/web-img/F_3_evvsi9.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510455/web-img/F_3_evvsi9.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510455/web-img/F_1_pmemv2.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510455/web-img/F_1_pmemv2.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510455/web-img/F_2_cguqfx.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510455/web-img/F_2_cguqfx.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510455/web-img/F_4_fkliye.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510455/web-img/F_4_fkliye.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510447/web-img/_87A1511s_foetqi.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510447/web-img/_87A1511s_foetqi.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510432/web-img/%E8%B3%87%E6%96%99_04_04_zbtoyq.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510432/web-img/%E8%B3%87%E6%96%99_04_04_zbtoyq.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510427/web-img/0010_v2jkvy.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510427/web-img/0010_v2jkvy.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510144/web-img/0006a_eup8wm.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510144/web-img/0006a_eup8wm.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510144/web-img/_87A1058_se3oqh.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510144/web-img/_87A1058_se3oqh.jpg'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510017/web-img/0030_2_wj1hlh.png',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510017/web-img/0030_2_wj1hlh.png'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510013/web-img/0030_1_ratqhx.png',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510013/web-img/0030_1_ratqhx.png'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510012/web-img/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88_2023-01-26_0.49.19_fv1bpt.png',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510012/web-img/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88_2023-01-26_0.49.19_fv1bpt.png'
        },
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510010/web-img/_87A2598_rxqg8l.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510010/web-img/_87A2598_rxqg8l.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510008/web-img/_87A2765_zbob0v.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510008/web-img/_87A2765_zbob0v.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510005/web-img/_87A2952_pjlxvo.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510005/web-img/_87A2952_pjlxvo.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510005/web-img/_87A3064_yfnfd3.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510005/web-img/_87A3064_yfnfd3.jpg'
        },
        {
          //bukken
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677510002/web-img/0026_dzqov1.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677510002/web-img/0026_dzqov1.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509995/web-img/0025_vujewb.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509995/web-img/0025_vujewb.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677509861/web-img/_87A0853_ikjzc9.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677509861/web-img/_87A0853_ikjzc9.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677545362/web-img/_87A0626_ghf6st.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677545362/web-img/_87A0626_ghf6st.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677545360/web-img/_87A0534_dwqbpd.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677545360/web-img/_87A0534_dwqbpd.jpg'
        }, 
        {
          //
          title:'',
          url:'https://res.cloudinary.com/stm-web/image/upload/v1677545354/web-img/_87A0905_mjynam.jpg',
          thumnail:'https://res.cloudinary.com/stm-web/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1677545354/web-img/_87A0905_mjynam.jpg'
        }, 
      ],
    }
  },
}
</script>
<style lang="scss">
.image-box{
  display: grid;
  place-content: center;
  gap: 1ch;
  a {
    clear: both;
    display: inline-block;
    position: relative;
    text-align: center;
    @include display_tab { 
       width: 50%;
    }
    @include display_pc { 
       width: 33%;
    }
    width: 100%;
    .bgbox {
      height: 150px;
      display:table-cell;
      vertical-align:middle;
      padding: 4px;
      border-radius: 2px;
      img { 
        width: 100%;
      }
    }
    .img-title {
      bottom: 5px;
      display: block;
      text-align: center;
      color: #999999;
      padding-top: 5px;
    }
    &:hover {
      .img-title { color: #232323; }
    }
  }
}
.v-gallery .lightBoxGallery {
    width: 100%;
    display: inline-block;
}
</style>