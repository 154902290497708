<template>
  <section class="home" />
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.home {
  padding-bottom: 0px;
}
</style>