export default {
    topImgs: [
      '/img/top.jpg',
      '/img/top2.jpg',
    ],
    menuItems: [
      {
        name: 'About',
        url: '/about'
      },
      {
        name: 'Work',
        url: '/work'
      },
      {
        name: 'Contact',
        url: '/contact'
      }
    ],
    gallaryImgs: {
      people: [],
      food: [],
      building: [],
      web: [],
      other: [],
    },
    images: [
      { index: 1,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
      { index: 2,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 3,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
      { index: 4,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 5,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 6,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
      { index: 7,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
      { index: 8,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 9,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
      { index: 10,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 11,
        src: "https://picsum.photos/200/300?random=1",
        category: "Cat"
      },
      { index: 12,
        src: "https://picsum.photos/200/300?random=1",
        category: "Dog"
      },
    ]
  }